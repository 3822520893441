import React from "react";
import pokeBall from '../poke_ball.svg';
import Search from "./Search";
import PropTypes from 'prop-types';

function Navbar({ isMobile }) {

    return (
        <div className="w3-top">
            <div style={isMobile ? { "display":"flex", "alignItems":"center", "justifyContent": "center" } : { "display":"flex", "alignItems":"center" }} className="w3-bar w3-white w3-card" id="myNavbar">
                <a href="/" className="w3-bar-item w3-button w3-wide"><img style={{ "width":"30px" }} src={pokeBall} alt="Pokeball" /></a>
                <div style={isMobile ? {} : { "width": "98%", "display": "flex", "justifyContent": "flex-end" }} className="w3-right">
                    <Search style={{ "marginRight": "16px" }} />
                </div>
            </div>
        </div>
    );
}

Navbar.propTypes = {
    isMobile: PropTypes.bool
};

export default Navbar;