import React from "react";
import Search from "../components/Search";
import Palkia from '../palkia.jpg';
import PropTypes from 'prop-types';

function Home({ isMobile }) {
  return (
    <header style={{ "backgroundImage": `url(${Palkia})`, "minHeight": "100vh", "backgroundPosition": "center", "backgroundSize": "cover" }} className="w3-display-container w3-grayscale-min" id="home">
      <div className="w3-display-left w3-text-white" style={isMobile ? { "minHeight":"500px", "padding":"48px" } : { "padding":"48px" }}>
        <span className="w3-xxxlarge w3-hide-small">Simple Pokémon Search</span><br />
        <span className="w3-xxlarge w3-hide-large w3-hide-medium">Simple Pokémon Search</span><br />
        <span className="w3-large">Evolutions, Type Charts, Learnsets - Just the info you need.</span><br />
        <div style={isMobile ? {"marginBottom":"32px"} : { "maxWidth":"400px", "marginTop": "40px" }} className="w3-button w3-white w3-padding-large w3-large w3-margin-top w3-opacity w3-hover-opacity-off">
          <Search />
        </div>
      </div> 
    </header>
  );
}

Home.propTypes = {
  isMobile: PropTypes.bool
};

export default Home;