import React, { useEffect } from "react";
import PropTypes from 'prop-types';

function Error({ isMobile }) {
    useEffect(() => {
        document.title="Page Could Not be Found...";
    });

    return(
        <div style={isMobile ? { "marginTop":"95px", "textAlign":"center", "minHeight":"62vh", "paddingLeft": "32px", "paddingRight": "32px" } : { "marginTop":"95px", "textAlign":"center", "minHeight":"75vh" }}>
            <h2>Page Could Not be Found...</h2>
            <p>Please refresh the page and try searching again!</p>
        </div>
    );
}

Error.propTypes = {
    isMobile: PropTypes.bool
};

export default Error;