import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Parser from 'html-react-parser';
import pokeBall from '../pokeball.gif';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

function Pokemon({ isMobile }) {
    const [pokemonState, setPokemonState] = useState({
        title: '',
        names: [],
        typeTable: '',
        intro: '',
        pokemon: '',
        mobileUpdated: '',
        html: '',
        learnset: '',
        gamelocations: '',
    });
    const [dataFetched, setDataFetched] = useState(false);
    const { slug } = useParams();

    useEffect(() => {
        if(pokemonState.title && dataFetched) {
            document.title = `${pokemonState.title} | Pokedex`;
        } else if (dataFetched) {
            document.title = 'Pokémon Not Found';
        } else {
            document.title = 'Loading...';
        }
    }, [pokemonState.title, dataFetched]);

    useEffect(() => {
        if(slug && !dataFetched) {
            searchPokemon(slug).then((result) => {
                // console.log(result);
                if(typeof result === "boolean" && result) {
                    setDataFetched(true);
                } else if (typeof result === "boolean") {
                    console.log("Making second request");
                    searchPokemon(slug).then(() => {
                        setDataFetched(true);
                    });
                } else {
                    setDataFetched(true);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug, dataFetched]);

    const searchPokemon = async (pokemon) => {
        // const self = this;
        let sanitizedPokemon = pokemon.replaceAll(" ", "_");
        try {
            let response = '';
            if(process.env.REACT_APP_NODE_ENV === "production") {
                //For production use
                response = await Axios.post("/.netlify/functions/pokedex/", [sanitizedPokemon]);
            } else {
                //For local testing use
                response = await Axios.post(`${process.env.REACT_APP_BASE_URL}/.netlify/functions/pokedex/`, [sanitizedPokemon]);
                // console.log(response);
            }
            // console.log(response.data[0]);
            let htmls = response.data;
            // console.log(htmls);

            if(response.status === 200) {
                const html = htmls[0];
                // console.log(html);
                let title = '';
                let typeTable = '';
                let intro = '';
                let image = '';
                let learnset = '';
                let gamelocations = '';
                if(htmls[1]) {
                    image = htmls[1];
                    image = image.split(`<div class="navheader">Picture</div>`)[1];
                    image = image.split(`<img src="`)[1];
                    image = image.split(`"`)[0];
                    image = `https://serebii.net${image}`;
                } else {
                    return false;
                }
                title = htmls[2];
                intro = html.split(`Artwork on Bulbagarden Archives`);
                intro = intro[1].split(`<p><b>`)[1];
                intro = intro.split(`<div id="toc"`)[0];
                intro = `<p><b>${intro}`;
                typeTable = html.split(`<span class="mw-headline" id="Type_effectiveness">Type effectiveness</span></h3>`)[1];
                typeTable = `<h3 class="in-block"><span class="mw-headline" id="Type_effectiveness">Type effectiveness</span></h3>${typeTable}`;
                typeTable = typeTable.split(`<span class="mw-headline" id="Learnset">Learnset</span></h3>`)[0];
                gamelocations = html.split(`<span class="mw-headline" id="Game_locations">Game locations</span></h3>`)[1];
                gamelocations = `<h3><span class="mw-headline" id="Game_locations">Game locations</span></h3>${gamelocations}`;
                gamelocations = gamelocations.split(`<span class="mw-headline" id="In_side_games">`)[0];
                learnset = html.split(`<span class="mw-headline" id="Learnset">Learnset</span></h3>`)[1];
                learnset = `<h3><span class="mw-headline" id="Learnset">Learnset</span></h3>${learnset}`;
                learnset = learnset.split(`<span class="mw-headline" id="By_breeding">`);
                if(learnset.length === 1){
                    learnset = learnset[0];
                    learnset = learnset.split(`<h3><span class="mw-headline" id="Side_game_data">`)[0];
                } else {
                    learnset = learnset[0];
                }
                
                intro = await fixLinks(intro);
                typeTable = await fixLinks(typeTable);
                gamelocations = await fixLinks(gamelocations);
                learnset = await fixLinks(learnset);
                // console.log(learnset);
                // console.log(image);
                // console.log(title);
                // console.log(intro);
                // console.log(typeTable);
                setPokemonStateFunc({
                    image,
                    title,
                    intro,
                    typeTable,
                    learnset,
                    gamelocations,
                    html,
                    "pokemon": sanitizedPokemon
                });
            }
            return true;
        } catch (error) {
            console.log(error);
            if(error.response.data === 'Could not find Pokemon') {
                return error.response.data;
            }
            return false;
        }
        
    };

    const setPokemonStateFunc = (updateObject) => {
        setPokemonState((prevPokemonState) => {
            let returnObject = { ...prevPokemonState, ...updateObject };
            return returnObject;
        });
    };

    const fixLinks = async (string) => {
        let links = string.split(`href="`);
        let url = "https://bulbapedia.bulbagarden.net";
        let html = `${links[0]}`;
        await Promise.all(links.map((item, index) => {
        if(index === 0) {
            return true;
        } else {
            if(item.substring(0,34) === url) {
                url = '';
            }
            html = html + `href="${url}${item}`;
            return true;
        }
        }));
        return html;
    };
  
    if(pokemonState.pokemon && dataFetched) {
        return (
            <>
                <div style={{ "marginTop":"80px", "display":"flex", "justifyContent":"center", "paddingBottom": "32px", "paddingLeft":"16px", "paddingRight":"16px" }}>
                    <div style={isMobile ? {"width":"100%"} : {"width":"50%"}}>        
                        <div style={{ "paddingLeft":"16px", "paddingRight":"16px" }}>
                            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center" }}>
                                <h2>{pokemonState.title}</h2>
                            </div>
                            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center" }}>
                                <img style={{ "maxWidth":"200px" }} src={pokemonState.image} alt={pokemonState.title} />
                            </div>
                            {Parser(pokemonState.intro)}
                        </div>
                        <div style={isMobile ? {"marginTop": "32px"} : {"marginTop": "48px"}}>
                            {Parser(pokemonState.typeTable)}
                        </div>
                        <div style={isMobile ? {} : {"marginTop":"48px"}}>
                            {Parser(pokemonState.gamelocations)}
                        </div>
                        <div style={isMobile ? {} : {}}>
                            {Parser(pokemonState.learnset)}
                        </div>
                    </div>
                </div>
            </>
        );
    } else if(dataFetched) {
        return(
            <>                    
                <div style={isMobile ? { "marginTop":"80px", "textAlign":"center", "minHeight":"62vh", "paddingLeft": "32px", "paddingRight": "32px" } : { "marginTop":"80px", "textAlign":"center", "minHeight":"75vh" }}>
                    <h2>We're sorry</h2>
                    <p>This pokémon could not be found! Please refresh the page and try searching again!</p>
                </div>                
            </>
        );
    } else {
        return(
            <>
                <div style={isMobile ? { "marginTop":"80px", "textAlign":"center", "minHeight":"62vh", "paddingLeft": "32px", "paddingRight": "32px" } : { "marginTop":"80px", "textAlign":"center", "minHeight":"78vh" }}>
                    <h2 style={{ "marginTop":"64px" }}>Loading...</h2>
                    <img style={{ "width":"150px" }} src={pokeBall} alt="Pokeball" />
                </div>
            </>
        );
    }
}

Pokemon.propTypes = {
    isMobile: PropTypes.bool
};

export default Pokemon;