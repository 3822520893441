import React from "react";
import Coffee from "./Coffee";
import PropTypes from 'prop-types';

function Footer({ isMobile }) {

  return (
    <footer>
      {window.location.pathname.includes("/pokemon") ? (
        <div>
          <div style={isMobile ? { "padding": "16px" } : { "paddingTop":"16px", "display": "flex", "justifyContent": "center" }} className="w3-center w3-black">
            <p className="w3-large w3-text-white">A big thank you to <a style={{ "color": "white" }} href="https://bulbapedia.bulbagarden.net/wiki/Main_Page">Bulbapedia</a> for putting all this information together.</p>
            {isMobile ? null : (
              <p style={{"marginLeft":"32px", "marginRight":"32px", "color": "white" }}> | </p>
            )}
            <p className="w3-large w3-text-white">Content is available under <a style={{ "color": "white" }} href="https://creativecommons.org/licenses/by-nc-sa/2.5/">Attribution-NonCommercial-ShareAlike 2.5</a>.</p>
          </div>
          <div style={{ "paddingBottom": "16px" }} className="w3-center w3-black">
            <Coffee style={{ "marginBottom": "16px" }} />
          </div>
        </div>
      ) : (
        <div style={{ "paddingLeft": "48px" }} className="w3-display-bottomleft w3-container">
          <p className="w3-large w3-text-white">A big thank you to <a style={{ "color": "white" }} href="https://bulbapedia.bulbagarden.net/wiki/Main_Page">Bulbapedia</a> for putting all this information together.</p>
          <p className="w3-large w3-text-white">Content is available under <a style={{ "color": "white" }} href="https://creativecommons.org/licenses/by-nc-sa/2.5/">Attribution-NonCommercial-ShareAlike 2.5</a>.</p>
          <Coffee style={{ "marginBottom": "26px" }} />
        </div>
      )}
    </footer>
  );
}

Footer.propTypes = {
  isMobile: PropTypes.bool
};

export default Footer;