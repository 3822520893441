import React from "react";
import PropTypes from 'prop-types';

function Coffee({ height, width, style }){
    return (
        <div style={style}>
            <a href="https://www.buymeacoffee.com/tannermetzmeier"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style={{ "height": height || '60px', "width": width || '217px' }} /></a> 
        </div>
    );
}

Coffee.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};

export default Coffee;