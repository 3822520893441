import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import PropTypes from 'prop-types';

function Search({ style }) {
    const [search, setSearch] = useState('');

    const newUrl = (e) => {
        e.preventDefault();
        let url = `/pokemon/${search}`
        window.location.assign(url);
    };

    return (
        <div style={style}>
            <form style={{ "display": "flex", "justifyContent": "center", "alignItems": "center",  }} onSubmit={(e) => newUrl(e)}>
                <input
                    style={{ "paddingBottom": "6px", "paddingTop": "6px", "border": "1px solid #ccc", "maxWidth":"185px" }}
                    type="text" 
                    placeholder="Pokémon Search.." 
                    name="search"
                    value={search}
                    onChange={(e) => setSearch((e.target.value).toLowerCase())}
                />
                <button style={{ "cursor": "pointer", "border": "1px solid #ccc", "padding":"8px 8px 4px 8px" }} type="submit"><FaSearch style={{}} /></button>
            </form>
        </div>
    );
}

Search.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    style: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
};

export default Search;