import React, { useState, useEffect } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Pokemon from "./pages/Pokemon";
import usePageTracking from "./components/usePageTracking";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  usePageTracking();

  // Start of Hook for mobile styling
  const isMobileThreshold = 768; // value in px
  const [isMobile, setIsMobile] = useState(window.innerWidth <= isMobileThreshold);

  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= isMobileThreshold);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  // End of Hook for mobile styling

  return (
    <>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home isMobile={isMobile} />} />
        <Route exact path="/pokemon/:slug" element={<Pokemon isMobile={isMobile} />} />
        <Route path="*" element={<Error isMobile={isMobile} />} />
      </Routes>
      <Footer isMobile={isMobile} />
    </>
  );
}

export default App;